import React from 'react';

const Intro = () => (
  <div className="intro">
    <h1 className="intro__text">
      I help businesses grow by creating software experiences that are simple
      and delightful.
    </h1>
  </div>
);

export default Intro;
