import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';

import Button from './Button';
import Select from './Select';

import avatar from '../images/brian-kulp.png';

export default function Header({ currentPath }) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const offset = window.pageYOffset;
      offset < 1 ? setScrolled(false) : setScrolled(true);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const classes = `header ${scrolled ? 'header--scrolled' : ''}`;

  return (
    <header className={classes}>
      <Link className="header__avatar-link" to="/">
        <img
          className="header__avatar"
          src={avatar}
          alt="Brian Kulp"
          width="62"
          height="62"
        />
      </Link>

      <nav>
        <Link to="/work">Work</Link>
        <Link to="/services">Services</Link>
        <Link to="/nice-words">Nice Words</Link>

        <Link to="/lets-talk" className="header__cta">
          <Button tabIndex="-1">Let's talk</Button>
        </Link>
      </nav>

      <Select
        value={currentPath}
        onChange={e => {
          navigate(e.target.value);
        }}
      >
        <option value="/">Home</option>
        <option value="/work/">Work</option>
        <option value="/services/">Services</option>
        <option value="/nice-words/">Nice Words</option>
        <option value="/lets-talk/">Let's Talk</option>
      </Select>
    </header>
  );
}
