import React from 'react';

import Header from './Header';
import Footer from './Footer';

import '../css/styles.scss';

const Layout = ({ children, scroll, maxFooter, currentPath }) => (
  <div className={`layout ${scroll ? 'layout--scroll' : ''}`}>
    <Header currentPath={currentPath} />
    <main className="layout__main">{children}</main>
    <Footer maxWidth={maxFooter} />
  </div>
);

export default Layout;
