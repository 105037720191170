import React from 'react';

import Twitter from '../svg/twitter.svg';
import Dribbble from '../svg/dribbble.svg';
import Github from '../svg/github.svg';

const Footer = ({ maxWidth }) => (
  <>
    <footer className={`footer ${maxWidth ? 'footer--max' : ''}`}>
      <div>© {new Date().getFullYear()} Brian Kulp Co.</div>
      <div className="footer__links">
        <a
          href="https://twitter.com/briankulp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" height="20" width="20" />
        </a>
        <a
          href="https://github.com/briankulp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Github} alt="Github" height="20" width="20" />
        </a>
        <a
          href="https://dribbble.com/briankulp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Dribbble} alt="Dribbble" height="20" width="20" />
        </a>
      </div>
    </footer>
  </>
);

export default Footer;
