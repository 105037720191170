import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import Intro from '../components/Intro';

const IndexPage = ({ location }) => (
  <Layout maxFooter currentPath={location.pathname}>
    <SEO
      title="Brian Kulp Co. | Freelance Designer and Developer"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />
    <Intro />
  </Layout>
);

export default IndexPage;
